body,
button {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* CRITERIO MEDIA QUERIES */
/* ?? MOBILE hasta 768px (base) */
/* ?? DESKTOP entre 769 y 1124 tablets */
/* ?? DESKTOP desde 1125 monitores  */
