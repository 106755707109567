.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide,
.swiper-slide-prev,
.swiper-slide-next {
  display: flex;
  flex-direction: column;
}

.why .swiper-slide {
  width: 40%;
}

.why .swiper-slide:nth-child(2n) {
  width: 40%;
}

.why .swiper-slide:nth-child(3n) {
  width: 40%;
}

.member .swiper-slide {
  width: 35%;
}

.member .swiper-slide:nth-child(2n) {
  width: 35%;
}

.member .swiper-slide:nth-child(3n) {
  width: 35%;
}

.work-desktop {
  display: none;
}

.work-mobile {
  display: block;
}

.work-mobile {
  height: 128px;
  border-radius: 35px;
  border: 3px solid #393939;
  width: calc(276px - 6px);
}

.work-mobile img {
  border-right: 3px solid #393939;
  border-left: 3px solid #393939;
}

.work-mobile .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.work-mobile .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* TEAMING WITH CLIENTS - HOME */
.teaming-with-clients .swiper-slide-next svg,
.teaming-with-clients .swiper-slide-prev svg {
  width: 49px;
  height: 22.882px;
  opacity: 0.5;
}

/* FIN TEAMING WITH CLIENTS - HOME Y EXPERTISE */

@media (max-width: 768px) {
  .why .swiper-slide {
    width: 195px;
    height: 310px;
  }

  .why .swiper-slide:nth-child(2n) {
    width: 195px;
    height: 310px;
  }

  .why .swiper-slide:nth-child(3n) {
    width: 195px;
    height: 310px;
  }

  .member .swiper-slide {
    width: 150px;
  }

  .member .swiper-slide:nth-child(2n) {
    width: 150px;
  }

  .member .swiper-slide:nth-child(3n) {
    width: 150px;
  }
}

@media (min-width: 1100px) {
  /* TEAMING WITH CLIENTS - HOME Y EXPERTISE */
  .teaming-with-clients .swiper-slide-next svg,
  .teaming-with-clients .swiper-slide-prev svg {
    width: 129px;
    height: 60.241px;
    opacity: 0.5;
  }
  /* FIN TEAMING WITH CLIENTS - HOME */

  .work-desktop {
    display: block;
  }

  .work-mobile {
    display: none;
  }

  .work-with-us {
    height: 128px;
    border-radius: 35px;
    border: 10px solid #393939;
  }

  .work-with-us img {
    border-right: 10px solid #393939;
    border-left: 10px solid #393939;
  }

  .work-with-us .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .work-with-us .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1125px) {
  .member .swiper-slide {
    width: 20%;
  }

  .member .swiper-slide:nth-child(2n) {
    width: 20%;
  }

  .member .swiper-slide:nth-child(3n) {
    width: 20%;
  }

  .member .swiper-slide:nth-child(4n) {
    width: 20%;
  }

  .member .swiper-slide:nth-child(5n) {
    width: 20%;
  }
}

/* DESKTOP ABOUT US */
.mySwiperAbout {
  width: 100%;
  height: 100%;
}

.mySwiperAbout .swiper-button-next,
.mySwiperAbout .swiper-button-prev {
  color: #474747;
}

.mySwiperAbout .swiper-pagination-bullet-active {
  background: #474747;
}

/* SWIPER HOME */
.why-swiper .swiper-slide {
  width: fit-content;
}
