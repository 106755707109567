* {
  margin: 0;
  padding: 0;
}

.textoregular {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
}

.object-contain {
  object-fit: contain;
}

.object-scale {
  object-fit: scale-down;
}
