.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

@media (max-width: 1100px) {
  .justify-evenly-mobile {
    justify-content: space-evenly;
  }

  .text-center-mobile {
    text-align: center;
  }

  .text-left-mobile {
    text-align: left;
  }

  .d-none-mobile {
    display: none;
  }
}

@media (min-width: 1100px) {
  .d-none-desktop {
    display: none;
  }
}
