.h1-mobile {
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}

.hero-section-alto-mobile {
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: underline;
}

.destacados-mobile {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

.semibold-mobile {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.h2-mobile {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.h3-mobile {
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0em;
}

.body-bold-mobile {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.txt-mobile {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.txt-minimo-mobile {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}

.txt-regular-mobile {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.txt-destacados-alta-mobile {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif;
}

.destacados-alta-mobile {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.extra-dest-mobile {
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-transform: none;
  letter-spacing: 0em;
}

.label-form {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

.input-form {
  padding: 4px;
  height: calc(31px - 8px);
  border-radius: 8px;
}

.select-form {
  padding: 4px;
  height: 31px;
  border-radius: 8px;
}

.input-form:focus,
.input-form:focus-visible,
.select-form:focus,
.select-form:focus-visible {
  outline: none;
}

.textarea-form {
  padding: 4px;
  height: calc(160px - 8px);
  border-radius: 8px;
}

.textarea-form:focus,
.textarea-form:focus-visible {
  outline: none;
}

.scroll-to-top {
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: initial !important;
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
}

.resaltado-mobile {
  text-shadow: #72f285 1px 1px 1px;
}

.resaltado-underline-mobile {
  text-decoration: underline;
  text-transform: uppercase;
}

.destacados-semibold-mobile {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
}

.destacados-italic-mobile {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
}

.underline-mobile {
  text-decoration: underline;
}

@media (min-width: 1100px) {
  .texto-regular {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }

  .important-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }

  .punteo-desktop {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }

  .texto-regular-bold {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .texto-negro {
    color: #393939;
  }

  .h1-web {
    font-size: 62px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
  }

  .h1-web-sub {
    font-size: 56px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-decoration: underline;
  }

  .destacado-bold {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .destacado-regular {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .destacado-italic {
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
  }

  .destacado-medium {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
  }

  .destacado-alta {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }

  .dropdown-title-web {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
  }

  .h3-web {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
  }

  .destacado-item {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
  }

  .h3-mayus {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 0em;
  }

  .h2-web {
    font-size: 48px;
    font-weight: 800;
    line-height: 52.8px;
    letter-spacing: 0em;
  }

  .title-card {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .input-form {
    background: #f6f3e8;
    border: 3px solid #393939;
    border-radius: 8px;
    height: 27px;
    padding: 8px;
    font-family: "Poppins", sans-serif;
  }

  .select-form {
    background: #f6f3e8;
    border: 3px solid #393939;
    border-radius: 8px;
    height: 49px;
    padding: 8px;
    font-family: "Poppins", sans-serif;
  }

  .input-form,
  .input-form:focus,
  .input-form:focus-visible,
  .select-form,
  .select-form:focus,
  .select-form:focus-visible {
    outline: none;
  }

  .textarea-form {
    font-family: "Poppins", sans-serif;
    background: #f6f3e8;
    border: 3px solid #393939;
    border-radius: 8px;
    height: 296px;
    padding: 8px;
  }

  .textarea-form,
  .textarea-form:focus,
  .textarea-form:focus-visible {
    outline: none;
  }

  .label-form {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .scroll-to-top {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: initial !important;
    border: none !important;
    box-shadow: none !important;
    width: 100% !important;
  }

  .resaltado {
    text-shadow: #72f285 1px 1px 1px;
  }

  .resaltado-underline {
    text-decoration: underline;
  }
}
